import '../scss/style.scss';

import $ from 'jquery';
import './lib/loading';
import './lib/spNav';
import './lib/fadeIn';
import './lib/tab';

$(function() {
});

window.onload = function () {
};
