import $ from "jquery";

$(function() {
    $('.js-tab-button').click(function () {
        var id = $(this).data('id')
        $('.js-tab-button:not([data-id="' + id + '"])').removeClass('is-active');
        $('.js-tab-button[data-id="' + id + '"]').addClass('is-active');
        $('.js-tab:not([data-id="' + id + '"])').attr('hidden', 'hidden');
        $('.js-tab[data-id="' + id + '"]').removeAttr('hidden');
    });
    $('.js-tab-button[data-event="eve"]').click(function () {
        $(".js-section").removeClass("is-main");
        $(".js-section").addClass("is-eve");
    });
    $('.js-tab-button[data-event="main"]').click(function () {
        $(".js-section").removeClass("is-eve");
        $(".js-section").addClass("is-main");
    });
    $('.js-tab-button[data-event="eve"]').click(function () {
        $(".js-text").removeClass("indigo500");
        $(".js-text").addClass("teal500");
    });
    $('.js-tab-button[data-event="main"]').click(function () {
        $(".js-text").removeClass("teal500");
        $(".js-text").addClass("indigo500");
    });
});
